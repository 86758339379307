import { Injectable, NgZone } from '@angular/core';
import WalletConnect from '@walletconnect/client';
import { ArianeeService } from '../arianee-service/arianee.service';
import { ArianeeWallet } from '@arianee/arianeejs/dist/src/core/wallet';
import { ModalController, NavController } from '@ionic/angular';
import { of } from 'rxjs';
import {
  ConnectToWebsiteModalComponent
} from '../../components/wallet-connect-modals/connect-to-website-modal/connect-to-website-modal.component';
import {
  WalletConnectionLoaderComponent
} from '../../components/wallet-connect-modals/wallet-connection-loader/wallet-connection-loader.component';
import {
  WalletConnectedModalComponent
} from '../../components/wallet-connect-modals/wallet-connected-modal/wallet-connected-modal.component';
import {
  supportedMethods,
  WalletConnectRequestHandlerService
} from './wallet-connect-request-handler/wallet-connect-requestHandler.service';
import { HttpClient } from '@angular/common/http';
import { WalletConnectRequestPayload } from '../../models/walletConnect';
import {
  WalletSignMessageModalComponent
} from '../../components/wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import { environment } from '../../../environments/environment';
import {
  WalletSwitchChainModalComponent
} from 'src/app/components/wallet-connect-modals/wallet-switch-chain-modal/wallet-switch-chain-modal.component';
import {
  WalletTransactionModalComponent
} from '../../components/wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component';
import { GasStationService } from '../gas-station-service/gas-station.service';
import { getCurrencySymbol, getExplorerOfChain, getProviderOfChain } from './chain-utils/chain-utils';
import {
  WalletErrorModalComponent
} from '../../components/wallet-connect-modals/wallet-error-modal/wallet-error-modal.component';
import { mergeMap, take } from 'rxjs/operators';
import {
  ScanCompatibleModalComponent
} from '../../components/wallet-connect-modals/scan-compatible-modal/scan-compatible-modal.component';
import { LoaderService, ToasterService } from '@arianeeprivate/wallet-shared-components';
import { TranslateService } from '@ngx-translate/core';
import { WalletSuccessModalComponent } from 'src/app/components/wallet-connect-modals/wallet-success-modal/wallet-success-modal.component';
import { IWalletConnectOptions, IWalletConnectSession, IClientMeta } from '@walletconnect/types';
import { EventLoggerService } from '../event-logger/event-logger-service';

@Injectable({
  providedIn: 'root'
})
export class WalletConnectService {
  static CONNECTION_TIMEOUT: number = 12750;
  static HANDSHAKE_TIMEOUT: number = 2750;

  private connector: WalletConnect;
  private wallet: ArianeeWallet;

  private connectionTimeout: NodeJS.Timer;

  private firstReqReceived: boolean;
  private connectedModal?: HTMLIonModalElement;

  private onScanProcessed?: Function = () => { };

  constructor (
    private arianeeService: ArianeeService,
    private modalCtrl: ModalController,
    private walletConnectRequestHandler: WalletConnectRequestHandlerService,
    private httpClient: HttpClient,
    private gasStation: GasStationService,
    private loaderService: LoaderService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private eventLoggerService:EventLoggerService,
    private ngZone: NgZone,
    private navCtrl: NavController
  ) {
    this.arianeeService.$wallet.subscribe((wallet) => {
      this.wallet = wallet;
      this.tryRestorePreviousSession();
    });
  }

  public handleLink = async (link: string, onScanProcessed: Function) => {
    this.onScanProcessed = onScanProcessed;

    this.arianeeService
      .$wallet
      .pipe(
        take(1),
        mergeMap(async () => {
          await this.initSession({ uri: link }, { killCurrentSession: true, timeoutPopup: true });
        })
      )
      .subscribe();

    return of(true);
  }

  private tryRestorePreviousSession = () => {
    const rawPreviousSession = localStorage.getItem('walletconnect');
    if (!rawPreviousSession) return false;

    const previousSession = JSON.parse(rawPreviousSession) as IWalletConnectSession;

    const wcOpts: IWalletConnectOptions = {
      session: previousSession
    };

    this.initSession(wcOpts, { killCurrentSession: false, timeoutPopup: false });
  }

  private tryKillCurrentSession = ({ forceReject }: { forceReject: boolean } = { forceReject: false }) => {
    return new Promise<void>(async (resolve) => {
      try {
        if (this.connector) {
          if (forceReject) { this.connector.rejectSession({ message: 'Forced Session Reject' }); } else { await this.connector.killSession(); }
        }
      } catch { }

      this.onDisconnect();
      resolve();
    });
  }

  private initWatcher () {
    this.eventLoggerService.logEvent('wallet-connect_initWatcher');

    this.connector.on('session_request', async (error, payload) => {
      this.eventLoggerService.logEvent('wallet-connect_onSession_request');
      console.info('[WalletConnect] SessionRequest', error, payload);
      if (error) throw error;

      clearTimeout(this.connectionTimeout);
      this.ngZone.run(() => { this.navCtrl.navigateForward('/tab/brand-list'); this.loaderService.dismiss(); this.onScanProcessed({ shutdownCamera: true }); });

      const params = payload.params[0];
      const startingChainId = await this.getStartingChainId(params.chainId);

      this.displayApproveSessionModal(startingChainId);
    });

    this.connector.on('call_request', async (error, payload: WalletConnectRequestPayload) => {
      console.info('[WalletConnect] CallRequest', error, payload);
      if (error) throw error;

      this.firstReqReceived = true;
      if (this.connectedModal && this.connectedModal.dismiss) this.connectedModal.dismiss();

      this.callRequestHandler(payload);
    });

    this.connector.on('connect', (error, payload) => {
      console.info('[WalletConnect] Connect', error, payload);
      if (error) throw error;
    });

    this.connector.on('session_update', (error, payload) => {
      console.info('[WalletConnect] SessionUpdate', error, payload);
      if (error) throw error;
    });

    this.connector.on('disconnect', (error, payload) => {
      this.eventLoggerService.logEvent('wallet-connect_onDisconnect');
      this.onDisconnect();
      if (error) throw error;
    });
  }

  private async initSession (wcOpts: IWalletConnectOptions, { killCurrentSession, timeoutPopup }: { killCurrentSession: boolean, timeoutPopup: boolean }) {
    this.eventLoggerService.logEvent('wallet-connect_initSession-start', wcOpts);
    if (timeoutPopup) this.connectionTimeout = setTimeout(this.onConnectionTimeout, WalletConnectService.CONNECTION_TIMEOUT);

    let chainId: number = 1;
    if (wcOpts.session && wcOpts.session.chainId) chainId = Number(wcOpts.session.chainId);

    const switchChainSuccess = await this.walletConnectRequestHandler.init(chainId);
    if (!switchChainSuccess) {
      this.connector.killSession({ message: 'This network is not yet supported' });
      this.onDisconnect();
      return;
    }

    await this.handleConnection(wcOpts, killCurrentSession);
    this.eventLoggerService.logEvent('wallet-connect_initSession-start-success', wcOpts);

    this.initWatcher();
  }

  private handleConnection = async (wcOpts: IWalletConnectOptions, killCurrentSession: boolean) => {
    if (!wcOpts.clientMeta) {
      wcOpts.clientMeta = {
        description: 'Arianee .Wallet App',
        url: 'https://arianee.org',
        icons: [''],
        name: '.Wallet'
      };
      // TODO: Toujours assigner la valeur ? Surchage pour les white-labels ?
    }

    try {
      if (killCurrentSession) await this.tryKillCurrentSession();
      this.connector = new WalletConnect(wcOpts);

      setTimeout(() => {
        const { handshakeId } = this.connector;

        // If the handshakeId is set to zero, this means that the handshake has already been consumed (accepted, rejected or neither of the two)
        // The user must generate a new session request from the dApp (scan a new QR code)
        if (!handshakeId || handshakeId == 0) this.onHandshakeError();
      }, WalletConnectService.HANDSHAKE_TIMEOUT);
    } catch (err) {
      this.eventLoggerService.logEvent('wallet-connect_handleConnection-error', err);
    }
  }

  private onHandshakeError = async () => {
    this.eventLoggerService.logEvent('wallet-connect_onHandshakeError');

    await this.tryKillCurrentSession({ forceReject: true });

    clearTimeout(this.connectionTimeout);
    this.ngZone.run(() => { this.loaderService.dismiss(); this.onScanProcessed({ shutdownCamera: false }); });

    const translation = await this.translateService.get('WalletConnect.handshakeError.button').toPromise();
    this.toasterService.alert({
      message: 'WalletConnect.handshakeError.text',
      buttons: [
        {
          text: translation
        }
      ]
    });
  }

  private onConnectionTimeout = async () => {
    this.eventLoggerService.logEvent('wallet-connect_initSession-timeout');

    await this.tryKillCurrentSession();

    clearTimeout(this.connectionTimeout);
    this.ngZone.run(() => { this.loaderService.dismiss(); this.onScanProcessed({ shutdownCamera: false }); });

    const translation = await this.translateService.get('WalletConnect.timeoutError.button').toPromise();
    this.toasterService.alert({
      message: 'WalletConnect.timeoutError.text',
      buttons: [
        {
          text: translation
        }
      ]
    });
  }

  private onDisconnect = () => {
    this.firstReqReceived = false;
    this.connectedModal = null;

    this.connector = null;
    localStorage.removeItem('walletconnect');
  }

  /**
   * We don't want to switch to a chain the wallet is unable to support (no known provider for the chain)
   * We therefore check if a provider can be found for the desired chain, in which case it can
   * can be used as the starting chain, or we default to the Ethereum mainnet chain otherwise.
   * @param desiredChainId the chain the dApp demand the wallet to be on
   * @returns the desired chain id if a provider was found, the ethereum mainnet chain id (1) otherwise
   */
  private async getStartingChainId (desiredChainId: number): Promise<number> {
    const mainnetChainId = 1;
    if (!desiredChainId) return mainnetChainId;

    const providerForDesiredChain = await getProviderOfChain(desiredChainId);
    return providerForDesiredChain ? desiredChainId : mainnetChainId;
  }

  // #region Handlers

  private callRequestHandler = async (payload: WalletConnectRequestPayload) => {
    if (!supportedMethods.includes(payload.method)) {
      this.connector.rejectRequest({
        id: payload.id,
        error: { message: 'JSON RPC method not supported' }
      });
      return;
    }

    const { accepted, skipped, errorMsgKey, showErrorPopup, preferredGasPrice, dismissLoaderFunc } = await this.callRequestModalHandler(payload);

    if (errorMsgKey) {
      this.connector.rejectRequest({
        id: payload.id,
        error: { message: '' }
      });

      if (showErrorPopup) {
        this.displayErrorModal({
          customTitle: this.translateService.instant('WalletConnect.error.title'),
          customMsg: this.translateService.instant(errorMsgKey)
        });
      }

      if (dismissLoaderFunc) dismissLoaderFunc();
      return;
    }

    if (skipped) {
      this.connector.approveRequest({
        id: payload.id,
        result: ''
      });

      if (dismissLoaderFunc) dismissLoaderFunc();
      return;
    }

    if (accepted) {
      try {
        if (payload.method === 'eth_signTransaction' || payload.method === 'eth_sendTransaction') payload.params[0].gasPrice = preferredGasPrice;

        const { result, errorMsg } = await this.walletConnectRequestHandler.handleRequest(payload);

        if (errorMsg) {
          this.connector.rejectRequest({
            id: payload.id,
            error: { message: errorMsg }
          });
          return;
        }

        if (payload.method === 'wallet_switchEthereumChain') {
          const chainId = Number(payload.params[0].chainId);
          this.updateSession(chainId);
        }

        this.connector.approveRequest({
          id: payload.id,
          result: result || ''
        });

        try {
          if (payload.method === 'eth_sendTransaction' && result && result.startsWith('0x')) {
            this.displaySuccessModal({ customMsg: this.translateService.instant('WalletConnect.success.tx') });
          }
        } catch { }
      } catch (err) {
        console.error(`[WalletConnect] An error has occurred: ${err}`);

        this.connector.rejectRequest({
          id: payload.id,
          error: { message: `An error has occurred: ${err}` }
        });

        this.displayErrorModal();
      }

      if (dismissLoaderFunc) dismissLoaderFunc();
      return;
    }

    if (!accepted) {
      this.connector.rejectRequest({
        id: payload.id,
        error: { message: 'Rejected by user' }
      });

      if (dismissLoaderFunc) dismissLoaderFunc();
    }
  }

  private callRequestModalHandler = async (payload): Promise<{ accepted?: boolean, skipped?: boolean, errorMsgKey?: string, showErrorPopup?: boolean, preferredGasPrice?: number, dismissLoaderFunc?: void | Function }> => {
    if (payload.method === 'wallet_switchEthereumChain') {
      const targetChainId = Number(payload.params[0].chainId);

      // Prevent many popup for the same switch chain request
      if (this.connector.session.chainId === targetChainId) return { skipped: true };

      // Prevent switching to an unknown chain (i.e without provider)
      const targetChainRpc = await getProviderOfChain(targetChainId);
      if (!targetChainRpc || targetChainRpc == '') {
        return {
          accepted: false,
          errorMsgKey: 'WalletConnect.error.unsupportedNetwork',
          showErrorPopup: true
        };
      }

      const accepted = await this.displaySwitchChainModal(targetChainId);
      return { accepted };
    } else if (payload.method === 'eth_signTransaction' || payload.method === 'eth_sendTransaction') {
      const { data, gasPrice: preferredGasPrice, loaderDismiss: loaderDismissModal } = await this.displayTransactionModal(payload);
      return { accepted: data, preferredGasPrice: preferredGasPrice, dismissLoaderFunc: loaderDismissModal };
    } else {
      const accepted = await this.displaySignMessageModal(payload);
      return { accepted };
    }
  }

  // #endregion

  // #region Connector Methods

  private approveSession = (chainId: number) => {
    this.connector.approveSession({
      chainId: chainId,
      accounts: [ // required
        this.wallet.address
      ]
    });
  }

  private updateSession = (chainId: number) => {
    this.connector.updateSession({
      chainId,
      accounts: [ // required
        this.wallet.address
      ]
    });
  }

  private rejectSession = () => {
    this.connector.rejectSession({ message: 'User rejected session request' });

    // There is no need to call killSession here because the session is already closed
    // this.connector.killSession({ message: 'User rejected session request' });
  }

  // #endregion

  // #region Modals

  private displayTimedLoader = async (message: string, loaderTime?: number): Promise<Function> => {
    return new Promise(async (resolve) => {
      const modal = await this.modalCtrl.create({
        component: WalletConnectionLoaderComponent,
        cssClass: 'modal-loading',
        backdropDismiss: false,
        componentProps: {
          message: message
        }
      });
      await modal.present();
      if (loaderTime) {
        setTimeout(() => {
          modal.dismiss();
          resolve(() => {});
        }, loaderTime);
      }
      resolve(() => modal.dismiss());
    });
  }

  private displayApproveSessionModal = async (chainId: number) => {
    this.eventLoggerService.logEvent('wallet-connect_displayApproveSessionModal');

    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: ConnectToWebsiteModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      try {
        this.approveSession(chainId);

        /*
        const translation = this.translateService.instant('WalletConnect.connection.connecting');
        const loaderDismiss = await this.displayTimedLoader(translation);

        await new Promise((resolve) => setTimeout(resolve, 600));
        loaderDismiss();
        */

        if (!this.firstReqReceived) {
          this.connectedModal = await this.displayConnectedModal();
        }
        this.eventLoggerService.logEvent('wallet-connect_displayApproveSessionModal-approve');
      } catch (err) {
        this.eventLoggerService.logEvent('wallet-connect_displayApproveSessionModal-reject', err);
        this.rejectSession();
      }
    } else {
      this.rejectSession();
    }
  }

  private displayConnectedModal = async () => {
    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletConnectedModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos
      }
    });
    modal.present();

    return modal;
  }

  /**
   * This is called from ScannerComponent on (WC) QR Code scan
   */
  public displayCompatibleModal = async () => {
    const modal = await this.modalCtrl.create({
      component: ScanCompatibleModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: true
    });
    modal.present();
  }

  private displaySignMessageModal = async (payload: WalletConnectRequestPayload) => {
    let message;
    switch (payload.method) {
      case 'eth_sign':
        message = payload.params[1];
        break;
      case 'personal_sign':
        message = payload.params[0];
        break;
      case 'eth_signTypedData':
        if (typeof payload.params[1] === 'string') {
          message = payload.params[1];
        } else {
          message = JSON.stringify(payload.params[1]);
        }
        break;
    }

    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletSignMessageModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos,
        message
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  private displayTransactionModal = async (payload: WalletConnectRequestPayload) => {
    const signOnly = payload.method === 'eth_signTransaction';
    const chainId = await this.walletConnectRequestHandler.chainId.pipe(take(1)).toPromise();

    const gasPrice = await this.gasStation.fetchGasStation(chainId.toString());
    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletTransactionModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos,
        symbol: getCurrencySymbol(chainId),
        payload,
        gasPrice,
        signOnly
      }
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data && payload.method === 'eth_sendTransaction') {
      const translation = await this.translateService.get('WalletConnect.transaction.sending').toPromise();
      const loaderDismiss = await this.displayTimedLoader(translation);

      return { data, gasPrice: gasPrice.fastest, loaderDismiss: loaderDismiss };
    }

    return { data, gasPrice: gasPrice.fastest };
  }

  private displaySwitchChainModal = async (chainId: number) => {
    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletSwitchChainModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos,
        chainId
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    return data;
  }

  private displayErrorModal = async ({ customTitle, customMsg }: { customTitle?: string, customMsg?: string } = {}) => {
    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletErrorModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos,
        customTitle,
        customMsg
      }
    });
    modal.present();
  }

  private displaySuccessModal = async ({ customTitle, customMsg }: { customTitle?: string, customMsg?: string }) => {
    const dAppInfos = await this.getPeerMetaXArianeeMeta(this.connector.peerMeta);

    const modal = await this.modalCtrl.create({
      component: WalletSuccessModalComponent,
      cssClass: 'modal-wallet-connect-style',
      backdropDismiss: false,
      componentProps: {
        ...dAppInfos,
        customTitle,
        customMsg
      }
    });
    modal.present();
  }

  // #endregion

  public getVerifiedInfos = async (peerMeta: IClientMeta): Promise<{ url?: string, img?: string }> => {
    const verifiedWebsiteList: Array<{ url: string, img?: string }> = await this.httpClient.get<any>(
      environment.walletConnectVerified, {
        responseType: 'json'
      }).toPromise().catch(e => []);

    const websiteHost = new URL(peerMeta.url).host;
    const verifiedWebsiteObject = verifiedWebsiteList.find(d => {
      return d.url === websiteHost;
    });

    return verifiedWebsiteObject || {};
  }

  public getPeerMetaXArianeeMeta = async (peerMeta: IClientMeta) => {
    const verifiedInfos = await this.getVerifiedInfos(peerMeta);

    return {
      logo: peerMeta.icons[0] || verifiedInfos.img,
      website: new URL(peerMeta.url).hostname,
      isVerified: verifiedInfos.url !== undefined
    };
  }

  public getDevToolsInfos = async () => {
    const account = this.walletConnectRequestHandler.account.getValue();
    const chainId = this.walletConnectRequestHandler.chainId.getValue();
    const rpcUrl = this.walletConnectRequestHandler.rpcUrl.getValue();

    return {
      connected: !!(this.connector && this.connector.connected),
      chainId,
      rpcUrl,
      getAddrExplorerUrl: async () => {
        if (chainId === -1 || rpcUrl === null || account === null) return null;

        const baseExplorerUrl = await getExplorerOfChain(chainId);
        if (baseExplorerUrl) return `${baseExplorerUrl}/address/${account}`;
        else return null;
      },
      disconnect: () => {
        if (!this.connector) return;

        this.connector.killSession({ message: 'User terminated session' });
        this.onDisconnect();
      }
    };
  }
}
