const UNIVERSAL_LINK_HTTPS_PREFIX = 'https://arianee.net/wc?uri=';
const DEEP_LINK_PREFIX = 'com.arianee.wallet://wc?uri=';
// https://arianee.net/wc?uri=wc:d3cad323-3b6b-40c9-97a0-6efff1f1f4c1@1%3Fbridge%3Dhttps%253A%252F%252F5.bridge.walletconnect.org%26key%3D43108559a309a8976ec2704e63a249dfda301bfb67a1243ab726859ad90226d7
// com.arianee.wallet://wc?uri=wc:00e46b69-d0cc-4b3e-b6a2-cee442f97188@1?bridge=https%3A%2F%2Fbridge.walletconnect.org&key=91303dedf64285cbbaf9120f6e9d160a5c8aa3deb67017a3874cd272323f48ae

export const getWcLinkFromDeeplink = (link: string): string => {
  let deepLink = null;
  if (link.startsWith(UNIVERSAL_LINK_HTTPS_PREFIX)) {
    deepLink = decodeURIComponent(link.replace(UNIVERSAL_LINK_HTTPS_PREFIX, ''));
  } else if (link.startsWith(DEEP_LINK_PREFIX)) {
    deepLink = decodeURIComponent(link.replace(DEEP_LINK_PREFIX, ''));
  } else {
    deepLink = link;
  }

  const regexResult = deepLink.match(/wc\:[a-zA-z0-9\-]*@[1-2]\?bridge=https(:|%3A)(%2F%2F|:\/\/)[a-zA-Z0-9\.\-\_]*&key=[0-9a-fA-F]*/);
  if (regexResult === null) return null;
  return regexResult[0];
};

const DUMMY_LINK = 'https://arianee.net/wc';
// https://arianee.net/wc

// WalletConnect "dummy" link are used on dapps side to open the wallet when a user interaction is needed
export const isWcDummyLink = (link: string): boolean => {
  return link === DUMMY_LINK;
};
