import { Component, Input, OnInit } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import Web3 from 'web3';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-wallet-sign-message-modal',
  templateUrl: './wallet-sign-message-modal.component.html',
  styleUrls: ['./wallet-sign-message-modal.component.scss']
})
export class WalletSignMessageModalComponent implements OnInit {
  @Input() logo: string;
  @Input() website: string;
  @Input() isVerified: boolean;

  @Input() message: string;

  headerConfig: IModalHeaderConfig = {
    logo: '',
    title: 'Sign this message',
    subtitle: {
      label: '',
      verified: false
    },
    description: ''
  }

  constructor (
    public modalController: ModalController,
    public translateService: TranslateService
  ) { }

  ngOnInit () {
    this.headerConfig.title = this.translateService.instant('WalletConnect.signMessage.signMessage');

    this.headerConfig.logo = this.logo;
    this.headerConfig.subtitle.label = this.website;
    this.headerConfig.subtitle.verified = this.isVerified;

    this.message = this.message.startsWith('0x') ? new Web3().utils.hexToUtf8(this.message) : this.message;
  }
}
