import { Injectable } from '@angular/core';
import { WalletConnectRequestPayload } from '../../../models/walletConnect';
import { ethers, Wallet } from 'ethers';
import { getProviderOfChain } from '../chain-utils/chain-utils';
import { TransactionRequest } from '@ethersproject/abstract-provider';
import { take } from 'rxjs/operators';
import { ArianeeService } from '../../arianee-service/arianee.service';
import { signTypedData_v4 } from 'eth-sig-util';
import { BehaviorSubject, Subject } from 'rxjs';

export const supportedMethods = [
  'eth_sendTransaction',
  'eth_sign',
  'eth_signTypedData',
  'eth_signTransaction',
  'personal_sign',
  'wallet_switchEthereumChain'
];

@Injectable({
  providedIn: 'root'
})
export class WalletConnectRequestHandlerService {
  private wallet: Wallet;

  public account: BehaviorSubject<string> = new BehaviorSubject(null);
  public chainId: BehaviorSubject<number> = new BehaviorSubject(-1);
  public rpcUrl: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor (private arianeeService: ArianeeService) { }

  public init = async (chainId: number = 1) => {
    const wallet = await this.arianeeService.$wallet.pipe(take(1)).toPromise();
    this.wallet = ethers.Wallet.fromMnemonic(wallet.mnemnonic);

    return this.trySwitchChain(chainId);
  }

  public handleRequest = async (payload: WalletConnectRequestPayload): Promise<{ result?: string; errorMsg?: string; }> => {
    let result, errorMsg;

    let transaction, dataToSign, addressRequested, chainId;

    switch (payload.method) {
      case 'eth_sendTransaction':
        transaction = payload.params[0];
        addressRequested = transaction.from;

        if (this.wallet.address.toLowerCase() === addressRequested.toLowerCase()) {
          const transactionResponse = await this.sendTransaction(transaction);
          result = transactionResponse.hash && transactionResponse.hash != '' ? transactionResponse.hash : null;
        } else {
          errorMsg = 'Address requested does not match active account';
        }
        break;
      case 'eth_sign':
        addressRequested = payload.params[0];
        dataToSign = payload.params[1];

        if (this.wallet.address.toLowerCase() === addressRequested.toLowerCase()) {
          result = await this.signMessage(dataToSign);
        } else {
          errorMsg = 'Address requested does not match active account';
        }
        break;
      case 'eth_signTypedData':
        addressRequested = payload.params[0];
        dataToSign = payload.params[1];

        if (this.wallet.address.toLowerCase() === addressRequested.toLowerCase()) {
          result = this.signTypedData(dataToSign);
        } else {
          errorMsg = 'Address requested does not match active account';
        }
        break;
      case 'eth_signTransaction':
        transaction = payload.params[0];
        addressRequested = transaction.from;

        if (this.wallet.address.toLowerCase() === addressRequested.toLowerCase()) {
          result = await this.signTransaction(transaction);
        } else {
          errorMsg = 'Address requested does not match active account';
        }
        break;
      case 'personal_sign':
        dataToSign = payload.params[0];
        addressRequested = payload.params[1];

        if (this.wallet.address.toLowerCase() === addressRequested.toLowerCase()) {
          result = await this.signPersonalMessage(dataToSign);
        } else {
          errorMsg = 'Address requested does not match active account';
        }
        break;
      case 'wallet_switchEthereumChain':
        chainId = payload.params[0].chainId;

        const success = await this.trySwitchChain(Number(chainId));
        if (success) {
          result = null;
        } else {
          errorMsg = 'Unable to switch to requested chain';
        }
        break;
    }

    return { result, errorMsg };
  }

  /**
   * https://github.com/WalletConnect/walletconnect-docs/issues/32
   */
  private async signMessage (dataToSign: string): Promise<string> {
    const dataToSignBytes = ethers.utils.arrayify(dataToSign);

    if (dataToSignBytes.length === 32) {
      // eth_sign (legacy)
      const signingKey = new ethers.utils.SigningKey(this.wallet.privateKey);
      const sigParams = await signingKey.signDigest(ethers.utils.arrayify(dataToSign));
      return ethers.utils.joinSignature(sigParams);
    } else {
      // eth_sign (standard)
      return this.signPersonalMessage(dataToSign);
    }
  }

  private signPersonalMessage (dataToSign: string): Promise<string> {
    return this.wallet.signMessage(
      ethers.utils.isHexString(dataToSign) ? ethers.utils.arrayify(dataToSign) : dataToSign
    );
  }

  private signTypedData (dataToSign: string): string {
    return signTypedData_v4(Buffer.from(this.wallet.privateKey.slice(2), 'hex'), {
      data: JSON.parse(dataToSign)
    });
  }

  /**
   * Switch to the chain with id chainId if possible
   * @param chainId chain to switch to
   * @returns Returns the chainId in hex format if switch succeeded, throws otherwise
   */
  public async trySwitchChain (chainId: number): Promise<boolean> {
    const chainRpcProvider = await getProviderOfChain(chainId);
    if (!chainRpcProvider) return Promise.resolve(false);

    this.wallet = this.wallet.connect(new ethers.providers.JsonRpcProvider(chainRpcProvider));

    this.account.next(this.wallet.address);
    this.chainId.next(chainId);
    this.rpcUrl.next(chainRpcProvider);

    return Promise.resolve(true);
  }

  private getNonce (): Promise<number> {
    return this.wallet.getTransactionCount('pending');
  }

  private async formatTransaction (payload): Promise<TransactionRequest> {
    return {
      to: payload.to,
      gasLimit: ethers.BigNumber.from(payload.gas || payload.gasLimit),
      gasPrice: ethers.utils.parseUnits('' + payload.gasPrice, 'gwei'),
      data: payload.data,
      value: payload.value,
      chainId: await this.chainId.pipe(take(1)).toPromise(),
      nonce: payload.nonce || await this.getNonce()
    };
  }

  public async signTransaction (transaction: any): Promise<string> {
    const tx = await this.formatTransaction(transaction);
    return this.wallet.signTransaction(tx);
  }

  public async sendTransaction (transaction: any): Promise<ethers.providers.TransactionResponse> {
    const tx = await this.formatTransaction(transaction);
    return this.wallet.sendTransaction(tx);
  }
}
