import { Component, Input, OnInit } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wallet-connected-modal',
  templateUrl: './wallet-connected-modal.component.html',
  styleUrls: ['./wallet-connected-modal.component.scss']
})
export class WalletConnectedModalComponent implements OnInit {
  @Input() logo: string;
  @Input() website: string;
  @Input() isVerified: boolean;
  headerConfig: IModalHeaderConfig = {
    logo: './assets/imgs/green-checkmark.png',
    title: 'Your wallet is connected',
    subtitle: {
      label: '',
      verified: false,
      logo: ''
    },
    description: 'Go back to your desktop website'
  }

  constructor (
    public modalController: ModalController,
    public translateService: TranslateService
  ) { }

  ngOnInit () {
    this.headerConfig.title = this.translateService.instant('WalletConnect.connected.isConnected');
    this.headerConfig.description = this.translateService.instant('WalletConnect.connected.description');

    this.headerConfig.subtitle.logo = this.logo;
    this.headerConfig.subtitle.label = this.website;
    this.headerConfig.subtitle.verified = this.isVerified;
  }
}
