import { Injectable } from '@angular/core';
import { NETWORK } from '@arianee/arianeejs';
import ArianeeWallet from '@arianee/arianeejs/dist/src/core/wallet';
import { CertificateSummary } from '@arianee/arianeejs/dist/src/core/wallet/certificateSummary';
import { intersectionBy, get, cloneDeep } from 'lodash';
import { ArianeeService } from '../arianee-service/arianee.service';

export interface PendingNft extends CertificateSummary {
  addedToPendingAt: string;
  network: NETWORK
}

@Injectable({
  providedIn: 'root'
})
export class PendingNftService {
  static readonly LOCAL_STORAGE_KEY: string = 'pendingNfts';
  static readonly LOCAL_STORAGE_WALLET_KEY: string = 'pendingNftsWallet';
  static readonly PENDING_NFT_EXPIRATION: number = 7 * 24 * 60 * 60 * 1000;

  constructor (
    private arianeeService: ArianeeService
  ) {
    this.arianeeService.$wallet.subscribe(this.onNewWallet);
  }

  private onNewWallet (wallet: ArianeeWallet) {
    const lastWalletAddress = localStorage.getItem(PendingNftService.LOCAL_STORAGE_WALLET_KEY);

    if (!lastWalletAddress) {
      localStorage.setItem(PendingNftService.LOCAL_STORAGE_WALLET_KEY, wallet.address);
    } else if (lastWalletAddress && wallet.address !== lastWalletAddress) {
      localStorage.setItem(PendingNftService.LOCAL_STORAGE_WALLET_KEY, wallet.address);
      localStorage.setItem(PendingNftService.LOCAL_STORAGE_KEY, '[]');
    }
  }

  /**
   * Returns the pending nfts
   * @param certificates optional, if set, pending nfts that intersects
   * with these certificates will be removed from pending nfts before returning the pending nfts
   * @returns an array of certificates
   */
  public getPendingNfts (network: NETWORK, certificates?: CertificateSummary[], removeExpired: boolean = false) : PendingNft[] {
    if (certificates) {
      this.refreshPendingNfts(network, certificates);
    }

    if (removeExpired) {
      this.removeExpiredPendingNfts(network);
    }

    try {
      return JSON.parse(localStorage.getItem(PendingNftService.LOCAL_STORAGE_KEY) || '[]')
        .filter((certificate: CertificateSummary) => get(certificate, 'network') === network);
    } catch {
      console.error(`Could not parse pending NFTs (value in local storage: ${localStorage.getItem(PendingNftService.LOCAL_STORAGE_KEY)})`);
      return [];
    }
  }

  public getPendingNft (network: NETWORK, certificateId: number) : PendingNft | null {
    return this.getPendingNfts(network).find(certificate =>
      get(certificate, 'certificateId') === certificateId && get(certificate, 'network') === network
    ) || null;
  }

  public addPendingNft (network: NETWORK, nft: CertificateSummary) {
    const pendingNfts = this.getPendingNfts(network);
    const pendingNftToAdd:PendingNft = {
      ...nft,
      network,
      addedToPendingAt: new Date().toISOString()
    };

    const alreadyInPendingNfts = !!pendingNfts.find((pendingNft) =>
      +get(pendingNftToAdd, 'certificateId') === +get(pendingNft, 'certificateId')
    );

    if (alreadyInPendingNfts) {
      return;
    }

    pendingNfts.push(pendingNftToAdd);
    localStorage.setItem(PendingNftService.LOCAL_STORAGE_KEY, JSON.stringify(pendingNfts));
  }

  private removePendingNft (network: NETWORK, certificateId: number) : void {
    const pendingNfts = this.getPendingNfts(network);
    const newPendingNfts = pendingNfts.filter(pendingNft =>
      +get(pendingNft, 'certificateId') !== certificateId ||
      (+get(pendingNft, 'certificateId') === certificateId && get(pendingNft, 'network') !== network)
    );
    localStorage.setItem(PendingNftService.LOCAL_STORAGE_KEY, JSON.stringify(newPendingNfts));
  }

  /**
   * Removes from pending nfts the nfts that intersects with the certificates passed in parameter.
   * (a pending nft is no longer a pending nft if it also is a non-pending nft).
   * @param certificates certificates to check intersection with
   */
  private refreshPendingNfts (network: NETWORK, certificates: CertificateSummary[]) : void {
    const cloned = cloneDeep(certificates);
    cloned.forEach(certificate => {
      Object.assign(certificate, { certificateId: +certificate.certificateId });
    });
    const inter = intersectionBy(cloned, this.getPendingNfts(network), 'certificateId');

    inter.forEach((certificate) => {
      const certificateId = +get(certificate, 'certificateId');
      this.removePendingNft(network, certificateId);
    });
  }

  /**
   * Removes the pending nfts that have been pending for more than a certain duration
   */
  private removeExpiredPendingNfts (network: NETWORK) {
    (this.getPendingNfts(network) as PendingNft[]).forEach(pendingNft => {
      const today = new Date();
      const pendingNftAddedAt = new Date(pendingNft.addedToPendingAt);
      if (today.getTime() - pendingNftAddedAt.getTime() > PendingNftService.PENDING_NFT_EXPIRATION) {
        this.removePendingNft(network, +get(pendingNft, 'certificateId'));
      }
    });
  }

  /**
   * @param certificates optional, if set, pending nfts that intersects
   * with these certificates will be removed from pending nfts before returning the pending nfts
   * @returns an array of pending nfts grouped by brand address
   */
  public getPendingNftsGroupedByBrand (network: NETWORK, certificates?: CertificateSummary[]): { [brandAddress: string]: CertificateSummary[] } {
    const res = {};

    this.getPendingNfts(network, certificates, true).forEach((certificate) => {
      const brandAddress = get(certificate, 'issuer.identity.address', '');
      if (!Array.isArray(res[brandAddress])) {
        res[brandAddress] = [];
      }

      res[brandAddress].push(certificate);
    });

    return res;
  }

  /**
   * Creates a new array of the certificates passed in parameter merged with the pending certificates of brand `identityAddress`
   * @param certificates certificates to merge with pending certificates, pending nfts that intersects
   * with these certificates will be removed from pending nfts before returning the pending nfts
   * @returns a new CertificateSummary array
   */
  public mergeCertificatesWithPendingOfBrand (network: NETWORK, certificates: CertificateSummary[], identityAddress: string): CertificateSummary[] {
    return this.getPendingNfts(network, certificates, true)
      .filter((pendingNft) => get(pendingNft, 'issuer.identity.address', '').toLowerCase() === identityAddress.toLowerCase())
      .map((certificate) => {
        delete certificate.network;
        delete certificate.addedToPendingAt;
        return certificate as CertificateSummary;
      })
      .concat(certificates || []);
  }

  public isPendingNft (certificateId: number) : boolean {
    return Object.values(NETWORK).some(
      (network) => !!this.getPendingNfts(network).find(certificate => +get(certificate, 'certificateId') === certificateId)
    );
  }
}
