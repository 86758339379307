import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ProductListItem } from '@arianeeprivate/wallet-shared-components';
import { PendingNftService } from '../../providers/pending-nft-service/pending-nft.service';

@Component({
  selector: 'app-brand-nft-list',
  templateUrl: './brand-nft-list.component.html',
  styleUrls: ['./brand-nft-list.component.scss']
})
export class BrandNftListComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() brandId: string;
  @Input() brandsCount = 0;
  @Input() brandName: string;
  @Input() brandLogo: string;
  @Input() subProducts: Array<ProductListItem> = [undefined, undefined];

  @Output() brandClick = new EventEmitter();
  @Output() productClick = new EventEmitter<string>();

  constructor (
    public pendingNftService: PendingNftService
  ) {}

  ngOnInit () {
  }

  public get scannedProduct () {
    return this.subProducts.filter(product => product !== undefined && product.scanned);
  }

  public get notScannedProduct () {
    return this.subProducts.filter(product => product !== undefined && !product.scanned);
  }
}
