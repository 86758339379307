import { Injectable } from '@angular/core';
import { UserService } from '../user-service/user.service';
import { HttpClient } from '@angular/common/http';
import { ArianeeService } from '../arianee-service/arianee.service';
import { map, mergeMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericCustodialService {
  constructor (
        private userService: UserService,
        private http: HttpClient,
        private arianeeService: ArianeeService
  ) {

  }

  /**
     * Get custodial provider if any
     * @param identityAddress
     * @private
     */
  private getCustodialWalletProvider (identityAddress: string): Observable<{ address: string, type: string, url: string } | undefined> {
    return this.arianeeService.$wallet
      .pipe(
        take(1),
        mergeMap(wallet => wallet.methods.getIdentity(identityAddress)),
        map(identity => {
          if (identity && identity.data && identity.data.providers) {
            return identity.data.providers.find(d => d.type === 'custodialWallet');
          }
        })
      );
  }

  private encryptAlgorithm={
    name: 'RSA-OAEP',
    modulusLength: 4096,
    publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
    hash: 'sha-512'
  };

  /**
     * Get mnemonic of current user and encrytped with address of custodial wallet provider
     * @param identityAddress
     * @private
     */
  private async getEncryptedMnemonic (identityAddress:string):Promise<string> {
    const custodialDetails = await this.getCustodialWalletProvider(identityAddress).toPromise();
    const mnemnonic = await this.userService.getMnemonic().pipe(take(1)).toPromise();

    const pubKeyCrypto = await window.crypto.subtle.importKey(
      'spki',
      this.pemToArrayBuffer(custodialDetails.address),
      this.encryptAlgorithm,
      true,
      ['encrypt']
    );

    const encodedMessage = new TextEncoder().encode(mnemnonic);
    const encrypted = await window.crypto.subtle.encrypt(
      this.encryptAlgorithm,
      pubKeyCrypto,
      encodedMessage
    );
    return new Buffer(encrypted).toString('hex');
  }

  /**
     * Retrieves user's mnemonic, encrypt it and append it to brand's custodial wallet provider
     * @param identityAddress
     */
  public async generateLinkToCustodialWalletLandingPage (identityAddress: string): Promise<string> {
    const encryptedMnemonic = await this.getEncryptedMnemonic(identityAddress);
    const urlCustodialWalletLandingPage = await this.getCustodialWalletProvider(identityAddress).pipe(take(1)).toPromise();

    const urlInstance = new URL(urlCustodialWalletLandingPage.url);
    urlInstance.searchParams.append('mnemonic', encryptedMnemonic);

    return urlInstance.href;
  }

  /**
     * Has brand a custodial wallet provider
     * @param identityAddress
     */
  public hasBrandCustodialWalletProvider (identityAddress: string): Observable<boolean> {
    return this.getCustodialWalletProvider(identityAddress).pipe(map(d => d !== undefined));
  }

  public removeLines (str) {
    return str.replace('\n', '');
  }

  public base64ToArrayBuffer (b64) {
    var byteString = window.atob(b64);
    var byteArray = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    return byteArray;
  }

  public pemToArrayBuffer (pem) {
    var b64Lines = this.removeLines(pem);
    var b64Prefix = b64Lines.replace('-----BEGIN PRIVATE KEY-----', '');
    var b64Final = b64Prefix.replace('-----END PRIVATE KEY-----', '');

    return this.base64ToArrayBuffer(b64Final);
  }
}
