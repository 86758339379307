import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PrivacyPolicyPopover } from './privacy-policy-popover/privacy-policy-popover.component';
import { NetworkWatemarkComponent } from './network-watermark/network-watemark.component';
import { ImportPopoverComponent } from './import-popover/import-popover.component';
import { NotiOptionComponent } from './noti-option/noti-option.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { CommonComponentsModule, PipeModule } from '@arianeeprivate/wallet-shared-components';
import { DeletePassportComponent } from './delete-passport/delete-passport.component';
import { ExportMnemonicComponent } from './export-mnemonic/export-mnemonic.component';
import { BrandNftListComponent } from './brand-nft-list/brand-nft-list.component';
import { PageHeaderForBrandComponent } from './page-header-for-brand/page-header-for-brand.component';
import { OnboardingSliderComponent } from './onboarding-slider/onboarding-slider.component';
import {
  OnboardingSliderItemComponent
} from './onboarding-slider/onboarding-slider-item/onboarding-slider-item.component';
import { PoapErrorModalComponent } from './poap-error-modal/poap-error-modal.component';
import { ModalHeaderComponent } from './wallet-connect-modals/components/modal-header/modal-header.component';
import {
  ModalListWithIconComponent
} from './wallet-connect-modals/components/modal-list-with-icon/modal-list-with-icon.component';
import {
  ConnectToWebsiteModalComponent
} from './wallet-connect-modals/connect-to-website-modal/connect-to-website-modal.component';
import {
  WalletConnectedModalComponent
} from './wallet-connect-modals/wallet-connected-modal/wallet-connected-modal.component';
import {
  WalletConnectionLoaderComponent
} from './wallet-connect-modals/wallet-connection-loader/wallet-connection-loader.component';
import {
  ScanCompatibleModalComponent
} from './wallet-connect-modals/scan-compatible-modal/scan-compatible-modal.component';
import {
  WalletSignMessageModalComponent
} from './wallet-connect-modals/wallet-sign-message-modal/wallet-sign-message-modal.component';
import {
  WalletTransactionModalComponent
} from './wallet-connect-modals/wallet-transaction-modal/wallet-transaction-modal.component';
import { WalletErrorModalComponent } from './wallet-connect-modals/wallet-error-modal/wallet-error-modal.component';
import {
  WalletSuccessModalComponent
} from './wallet-connect-modals/wallet-success-modal/wallet-success-modal.component';
import {
  WalletSwitchChainModalComponent
} from './wallet-connect-modals/wallet-switch-chain-modal/wallet-switch-chain-modal.component';

const components = [
  PrivacyPolicyPopover,
  NetworkWatemarkComponent,
  ImportPopoverComponent,
  NotiOptionComponent,
  DeletePassportComponent,
  ExportMnemonicComponent,
  BrandNftListComponent,
  PageHeaderForBrandComponent,
  OnboardingSliderComponent,
  OnboardingSliderItemComponent,
  ModalHeaderComponent,
  PoapErrorModalComponent,
  ModalListWithIconComponent,
  ConnectToWebsiteModalComponent,
  WalletConnectedModalComponent,
  WalletConnectionLoaderComponent,
  ScanCompatibleModalComponent,
  WalletSignMessageModalComponent,
  WalletSwitchChainModalComponent,
  WalletTransactionModalComponent,
  WalletErrorModalComponent,
  WalletSuccessModalComponent
];
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    MomentModule,
    FormsModule,
    PipeModule,
    CommonComponentsModule
  ],
  declarations: components,
  exports: components,
  entryComponents: components
})
export class ComponentsModule { }
