import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EventLoggerService } from '../event-logger/event-logger-service';
import { TranslateService } from '@ngx-translate/core';
import {Arianee, NETWORK} from "@arianee/arianeejs/dist/src";
import {UserService} from "../user-service/user.service";
import {ArianeeService} from "../arianee-service/arianee.service";

declare const grecaptcha: any;

@Injectable({
  providedIn: 'root'
})
export class AuthJWTService {
  constructor (private httpClient: HttpClient, private translateService: TranslateService, private userService: UserService) {}

  private getJWT = async (
    arianeeAccessToken: string,
    recaptchaType: 'recaptchaV2' | 'recaptchaV3',
    recaptchaToken: string,
    network:NETWORK
  ) => {
    return await this.httpClient
      .post(
        `${environment.jwtAuthUrl}/auth/getJWT/recaptcha`,
        {
          network
        },
        {
          headers: {
            aat: arianeeAccessToken,
            authorization: `${recaptchaType} ${recaptchaToken}`
          }
        }
      )
      .pipe(take(1))
      .toPromise();
  };

  private getRecaptchaV3 = async () => {
    return await grecaptcha.execute('6LfnOuAgAAAAAN7zh9poH-jYfZhbIgqq4E3uNsox', {
      action: 'submit'
    });
  };

  private getRecaptchaV2 = async (): Promise<string> => {
    const overlay = (document.querySelector('#recaptchaV2-overlay') as HTMLElement);
    const container = (document.querySelector('#recaptchaV2-container') as HTMLElement);

    overlay.style.display = 'flex';
    container.style.display = 'flex';

    document.querySelector('#recaptchaV2-container p').innerHTML = this.translateService.instant('recaptcha.paragraph');
    return new Promise((resolve, reject) => {
      const id = grecaptcha.render('recaptchaV2', {
        sitekey: '6LcYnxAhAAAAANSYV0e70aG-POOY2IaQlnTJwYgs'
      });

      const checkRecaptchaV2Response = setInterval(() => {
        const response = grecaptcha.getResponse(id);

        if (response.length !== 0) {
          clearInterval(checkRecaptchaV2Response);

          overlay.style.display = 'none';
          container.style.display = 'none';
          resolve(response);
        }
      }, 1000);
    });
  };

  public authJwtGetter = async (arianeeAccessToken: string) => {
    return new Promise((resolve, reject) => {
      const recaptchaReadyTimeout = setTimeout(() => {
        resolve('RECAPTCHA_TIMEOUT');
      }, 15000);

      grecaptcha.ready(async () => {
        clearTimeout(recaptchaReadyTimeout);

        let recaptchaV3 = '';
        let jwt = '';
        const chain = await this.userService.chain.get().pipe(take(1)).toPromise();

        try {
          recaptchaV3 = await this.getRecaptchaV3();
          jwt = (await this.getJWT(arianeeAccessToken, 'recaptchaV3', recaptchaV3, chain)) as string;
          resolve(jwt);
        } catch (e) {
          // Resolve even if there is an error, this will give us a chance to
          // keep the faucet alive in case of problems with recaptcha/jwt-generator
          if (recaptchaV3 === '') {
            console.error('authJwtGetter: Error with recaptcha', e);
            resolve('RECAPTCHA_FAILED');
          } else if (jwt === '') {
            console.error('authJwtGetter: Error with JWT', e);

            // If the jwt generator request that we retry with a recaptcha v2 (checkbox)
            if (e.error.action && e.error.action === 'retryWithV2') {
              const recaptchaV2 = await this.getRecaptchaV2();
              jwt = (await this.getJWT(arianeeAccessToken, 'recaptchaV2', recaptchaV2, chain)) as string;
              resolve(jwt);
            } else {
              resolve('JWT_FAILED');
            }
          } else {
            console.error('authJwtGetter: Unknown error', e);
            resolve('SOMETHING_FAILED');
          }
        }
      });
    });
  };
}
