import { Component, Input, OnInit } from '@angular/core';
import { IModalHeaderConfig } from '../components/modal-header/modal-header.component';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wallet-success-modal',
  templateUrl: './wallet-success-modal.component.html',
  styleUrls: ['./wallet-success-modal.component.scss']
})
export class WalletSuccessModalComponent implements OnInit {
  @Input() website: string;
  @Input() isVerified: boolean;

  @Input() customTitle: string;
  @Input() customMsg: string;

  headerConfig: IModalHeaderConfig = {
    logo: '',
    title: '',
    subtitle: {
      label: '',
      verified: false,
      logo: ''
    },
    description: ''
  };

  constructor (
    public modalController: ModalController,
    public translateService: TranslateService
  ) { }

  ngOnInit () {
    this.headerConfig.logo = './assets/imgs/green-checkmark.png';
    this.headerConfig.subtitle.label = this.website;
    this.headerConfig.subtitle.verified = this.isVerified;

    if (this.customTitle && this.customTitle != '') this.headerConfig.title = this.customTitle;
    else this.headerConfig.title = this.translateService.instant('WalletConnect.success.title');

    if (this.customMsg && this.customMsg != '') this.headerConfig.description = this.customMsg;
    else this.headerConfig.description = this.translateService.instant('WalletConnect.success.description');
  }
}
